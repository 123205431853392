export const footerItems = {
    primary : [
        { id : 1, label : 'Tattle', target: 'http://tattle.co.in', type:'external'},
        { id : 2, label : 'Blog', target: 'http://blog.tattle.co.in', type:'external'},
        { id : 3, label : 'Status', target: '/status', type:'internal'},
        { id : 4, label : 'Contribute', target: '/contribute', type:'internal'},
    ],
}


export const primaryNav = {
    options : [
        {id: 1, label: 'Explore', target:'/khoj/explore'},
        {id: 2, label: 'Search', target:'/khoj/search'},
        {id: 3, label: 'About', target:'/khoj/about'},
    ]
}